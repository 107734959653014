html[theme="DEFAULT"] {
  --dropdownText: #FFFFFF;
  --dropdownBorder: rgba(22, 25, 28, 0.3);
  --dropdownHover: rgba(22, 25, 28, 0.3);

  --dropdownBg: rgba(41, 46, 51, 0.6);
  --labelText: #545F69;
  --primaryBg: #292E33;
  --secondaryBg: rgba(22,25,28,0.3);
  --borderBg: #292E33;
  --brandColor: #ED663F;
}

html[theme="LIGHT"] {
  --dropdownText: #31394D;
  --dropdownBorder: #DBDEEB;
  --dropdownHover: #CCCED6;

  --dropdownBg: #CCCED6;
  --labelText: #B1B5C8;
  --primaryBg: #FFFFFF;
  --secondaryBg: #CCCED6;
  --borderBg: #DBDEEB;
  --brandColor: #ED663F;
}

.react-select__control {
  background: var(--dropdownBg) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  padding: 2px 8px;
  color: var(--dropdownText) !important;
  font-size: 14px;
  border: none !important;
  min-width: 454px;
  box-shadow: 0 0 0 1px var(--dropdownBg) !important;
}

.react-select__value-container {
  padding: 0 8px !important;
}

.react-select__placeholder {
  color: var(--labelText) !important;
}

.react-select__input, .react-select__single-value {
  color: var(--dropdownText) !important;
}

.react-select__indicator-separator {
  background-color: #FFFFFF !important;
}

.react-select__indicator {
  svg {
    fill: #FFFFFF;
    stroke: #FFFFFF;
  }
}

.react-select__menu {
  background: var(--primaryBg) !important;
  color: var(--dropdownText) !important;
  margin: 0 !important;
  border-radius: 0 0 8px 8px !important;
}

.react-select__menu-list {
  padding: 0 !important;
  :last-child {
    border-radius: 0 0 8px 8px !important;
  }
}

.react-select__option {
  text-align: left;
  line-height: 16px;
  color: var(--dropdownText) !important;
  padding: 16px 33px 16px 16px !important;
  cursor: pointer !important;
  border-color: var(--dropdownBorder) !important;
  border-style: solid !important;
  border-width: 0px 1px 1px 1px !important;
  
  :hover {
    background: var(--dropdownHover) !important;
  }
}

.react-select__option--is-selected, .react-select__option:hover {
  background: var(--dropdownHover) !important;
}

.react-select__option--is-focused {
  background: none !important;
}

.react-select__menu-list::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}
.react-select__menu-list::-webkit-scrollbar-track {
  background: transparent !important;
}
.react-select__menu-list::-webkit-scrollbar-thumb {
  background: var(--brandColor);
  border-radius: 8px;
}
