$secondary-text: #717E8A;
$accept-button-bg: #41ca88;

.relative {
  position: relative;
}

.track-horizontal {
  background-color: transparent;
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 5px !important;
}

.track-horizontal div {
  background-color:rgba(237, 102, 60, 0.7) !important;
}

@mixin for-ipad-only {
    @media (min-width: 768px) and (max-width: 1024px) { @content; }
}

@mixin for-mobile-only {
  @media (max-width: 767px) { @content; }
}


