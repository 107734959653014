@import '../../../GlobalStyle/variables.scss';

.custom-scrollbar {
  div {
    &:last-child {
      div {
        background-color: $secondary-text !important;
      }
    }
  }
  .update-post {
    &:last-child {
      div {
        background-color: transparent !important;
      }
    }
  }
}
