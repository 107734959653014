html[theme="DEFAULT"] {
  --codeMirror: #EEFFFF;
  --lineNumber: #546E7A;
  --cursor: #FFCC00;
  --comment: #0C7;
  --atom: #F78C6C;
  --number: #DECB6B;
  --property: #DECB6B;
  --attribute: #C792EA;
  --keyword: #C792EA;
  --string: #CE9178;
  --string2: #4EC9B0;
  --variable2: #DCDCAA;
  --def: #82AAFF;
  --bracket: #EEFFFF;
  --tag: #FF5370;
  --operator: #89DDFF;
  --error: rgba(255, 255, 255, 1.0);
}
  
html[theme="LIGHT"] {
  --codeMirror: #41323f;
  --lineNumber: #8d8687;
  --cursor: #776e71;
  --comment: #e96ba8;
  --atom: #815ba4;
  --number: #815ba4;
  --property: #005cc5;
  --attribute: #005cc5;
  --keyword: #ef6155;
  --string: #fec418;
  --string2: #48b685;
  --variable2: #06b6ef;
  --def: #f99b15;
  --bracket: #41323f;
  --tag: #ef6155;
  --operator: #815ba4;
  --error: #776e71;
}

.cm-s-default {
    padding: 2px 8px;
}

.cm-s-default.CodeMirror {
  color: var(--codeMirror);
}

.cm-s-default div.CodeMirror-selected {
  background: transparent;
}

.cm-s-default .CodeMirror-line::selection,
.cm-s-default .CodeMirror-line>span::selection,
.cm-s-default .CodeMirror-line>span>span::selection {
  background: transparent;
}

.cm-s-default .CodeMirror-line::-moz-selection,
.cm-s-default .CodeMirror-line>span::-moz-selection,
.cm-s-default .CodeMirror-line>span>span::-moz-selection {
  background: transparent;
}
  
.cm-s-default .CodeMirror-gutters {
  /* color: #546E7A; */
  border: none;
}

.cm-s-default .CodeMirror-guttermarker,
.cm-s-default .CodeMirror-guttermarker-subtle,
.cm-s-default .CodeMirror-linenumber {
  color: var(--lineNumber);
}
  
.cm-s-default .CodeMirror-cursor {
  border-left: 1px solid var(--cursor);
}
  
.cm-s-default .cm-comment {
  color: var(--comment);
}

.cm-s-default .cm-atom {
  color: var(--atom);
}

.cm-s-default .cm-number {
  color: var(--number);
}

.cm-s-default .cm-property {
  color: var(--property); 
}

.cm-s-default .cm-attribute {
  color: var(--attribute);
}

.cm-s-default .cm-keyword {
  color: var(--keyword);
}

.cm-s-default .cm-string {
  color: var(--string); 
}

.cm-s-default .cm-string-2 {
  color: var(--string2);
}

.cm-s-default .cm-variable {
  color: var(--string2);  /*#f07178; */
}

.cm-s-default .cm-variable-2 {
  color: var(--variable2);
}

.cm-s-default .cm-def {
  color: var(--def);
}

.cm-s-default .cm-bracket {
  color: var(--bracket);
}

.cm-s-default .cm-tag {
  color: var(--tag);
}

.cm-s-default .cm-operator,
.cm-s-default .cm-link {
  color: var(--operator);
}

.cm-s-default .cm-error {
  color: var(--error);
}

.cm-s-default.CodeMirror-focused div.CodeMirror-selected {
  background: transparent;
}

.cm-s-default .CodeMirror-activeline-background {
  background: transparent;
}

.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
  color: #f07178;
}

.cm-s-default .cm-builtin {
  color: #FFCB6B;
}

.cm-s-default .cm-meta {
  color: #FFCB6B;
}

.cm-s-default .cm-qualifier {
  color: #DECB6B;
}

.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
  color: #DECB6B;
}

.cm-s-default .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
