@import './variables.scss';

html[theme="DEFAULT"] {
  --codeBg: #151718;
  --primaryText: #FFFFFF;
  --primaryBg: #292E33;
  --secondaryBg: #16191C;
  --borderColor: rgba(22,25,28,0.3);
  --borderLi: transparent;
  --secondaryText: #717E8A;
}

html[theme="LIGHT"] {
  --codeBg: #FFFFFF;
  --primaryText: #31394D;
  --primaryBg: #FFFFFF;
  --secondaryBg: #CCCED6;
  --borderColor: #DBDEEB;
  --borderLi: 1px solid #DBDEEB;
  --secondaryText: #B1B5C8;
}

.slide-up-enter,
.slide-up-appear {
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-leave {
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-leave {
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-leave {
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-leave {
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes antSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
}
@keyframes antSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
}
@keyframes antSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0.8);
  }
}
@keyframes antSlideLeftIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
}
@keyframes antSlideLeftOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
}
@keyframes antSlideRightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1);
  }
}
@keyframes antSlideRightOut {
  0% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8);
  }
}

.rc-editor-mention-wrapper {
  cursor: text;
  line-height: 36px;
  height: auto;
  background: var(--codeBg);
  border-radius: 18px;
  display: flex;
  align-items: center;
  min-height: 36px;
  font-size: 14px;
  padding: 6px 32px 6px 12px;
  color: var(--primaryText);
  background: rgb(22, 25, 28) none repeat scroll 0% 0%;
  resize: none;
  outline: currentcolor none medium;
  border: 2px solid transparent;
}

.rc-editor-mention-wrapper .DraftEditor-root {
  position: relative;
}

.rc-editor-mention-editor {
  width: 100%;
  outline: 0;
  -moz-appearance: textfield;
  line-height: 16px;
  transition: all 0.3s ease;
  border: 0;
  border-radius: 5px;
  padding: 0;
}

.rc-editor-mention-editor-wrapper {
  overflow-y: auto;
}

.rc-editor-mention-dropdown {
  position: absolute;
  background-color: var(--primaryBg);
  max-height: 300px;
  overflow-y: auto;
  line-height: 1;
  border: 1px solid var(--codeBg);
  margin-top: 26px;
  min-width: 260px;
  max-width: 500px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  z-index: 5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
      flex-direction: column;
  box-sizing: border-box;
}

.rc-editor-mention-dropdown-item {
  padding: 10px 8px;
  -webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  color: var(--primaryText);
  display: flex;
  align-items: center;
}

.rc-editor-mention-dropdown-item .mention-detail {
  display: flex;
  flex-direction: column;
}

.mention-detail .mention-username {
  font-size: 15px;
}

.mention-detail .mention-user-name {
  font-size: 13px;
  margin-top: 4px;
}

.tag-avatar {
  width: 16px;
  height: 16px;
}

.slide-up-enter.slide-up-enter-active-placement-bottomLeft,
.slide-up-appear.slide-up-appear-active-placement-bottomLeft {
  animation-name: antSlideUpIn;
}

.slide-up-enter.slide-up-enter-active-placement-topLeft,
.slide-up-appear.slide-up-appear-active-placement-topLeft {
  animation-name: antSlideDownIn;
}

.slide-up-leave.slide-up-leave-active-placement-bottomLeft {
  animation-name: antSlideUpOut;
}

.slide-up-leave.slide-up-leave-active-placement-topLeft {
  animation-name: antSlideDownOut;
}

.tag-row {
  padding: 7px 9px 6px;
}

.tag-row span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tag-row .meta {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2px;
}

.tag-row .company-name {
  font-size: 12px;
  line-height: 14px;
  color: var(--secondaryText);
}

.DraftEditor-editorContainer {
    color: var(--primaryText);
}
.public-DraftEditorPlaceholder-root {
  position: absolute;
}
.public-DraftEditorPlaceholder-inner {
  font-size: 14px;
  color: var(--secondaryText);
  line-height: 16px;
}
.rc-editor-mention-dropdown-item:hover {
  background-color: var(--secondaryBg);
}

.mention-comment-col {
  width: 100%;
}

.public-DraftStyleDefault-block {
  text-align: left;
}

.public-DraftStyleDefault-block span {
  background-color: transparent !important;
}

.public-DraftStyleDefault-block span[data-text=true] {
  color: var(--primaryText);
}

.mention-comment-col__input {
  position: relative !important;
  color: var(--primaryText);
  border: none;
  background: var(--codeBg) !important;
  padding: 6px 10px;
  height: 110px !important;
  border-radius: 18px;
  border: 2px solid transparent;
  line-height: 19px;
  &:focus {
    border-color: $accept-button-bg;
  }
}

.mention-comment-col__suggestions{
  border-radius: 15px;
  top: 22px !important;
  font-size: 16px;
  text-align: left;
}

.mention-comment-col__input-focus {
  border-color: $accept-button-bg;
}

.mention-comment-col__suggestions__list{
  background: var(--primaryBg);
  color: var(--primaryText);
  border: var(--borderLi);
  box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
}

.mention-comment-col__suggestions__item{
  padding: 16px;
  border-bottom:  1px solid var(--borderColor);
}

.mention-comment-col__suggestions__item:hover{
  background-color: var(--secondaryBg);
}

.mention-comment-col__highlighter {
  display: flex !important;
  align-items: flex-start;
  position: absolute !important;
}

.ifnkhs {
  height: 110px;
}

@include for-ipad-only {
  .public-DraftEditorPlaceholder-inner {
    font-size: 12px;
  }
}

@include for-mobile-only {
  .public-DraftEditorPlaceholder-inner {
    font-size: 13px;
  }
}